<template>
	<prac-test :test=test>
	</prac-test>
</template>
<script>
import { PracTest } from "@/components";
export default {
  name: "network-layer",
  components: {
    PracTest
  },
  data() {
    return {
      test: {
        date: "January 2019",
        title: "Network Security",
        subtitle: "Learn more about Network Security.",
        description: "Online flashcards about network security.",
        bg: "/img/tests/pixb/net-security-hacker.jpg",
        author: "Networks",
        references: "",
        items: [
          {
            front: "<h4>What is cryptography?</h4>",
            back:
              "Cryptography is a science that involves integrity, confidentiality, authenticitty and non-repudiation."
          },
          {
            front: "<h4>What is an intrusion detection system?</h4>",
            back:
              "An IDS is a system that analyzs a network (it is a packet sniffer) for for suspicious activity in order to detect intrusions. It raises an alarm to the administrator in ase it detects something suspicious. It is generally connected to a whole LAN or a segment."
          },
          {
            front: "What are <b>honey pots</b>?",
            back:
              "Honey pots are systems used to lure hackers into. Ideally, the hacker thinks that the honey pot is a real system so that there is enough time to identify the hacker."
          },
          {
            front: "Is authentication and authenticity the same?",
            back:
              "Authentication is the process of establishing and verifying with a valid station. And a station is authentic when it is what it claims to be."
          },
          {
            front: "What is the difference between authenticity and integrity?",
            back:
              "What are the advantage of symmetric- over asymmetric encryption?"
          },
          {
            front: "What is non-repudiation?",
            back:
              "Non-repudiation is the ensurance that nobody can deny the validity of something, nor its authorship."
          },
          {
            front: "What is the difference of a worm and a virus?",
            back:
              "Unlike a virus, worms are independently executable. Worms are injected into the network and life on their own. A virus nomally reside in files or programs."
          },
          {
            front: "What is a firewall?",
            back:
              "A firewall is a program or hardware that monitors outgoing and incoming traffic inorder to control he access between networks/intranets. Packets are either blocked or allowed according to a defined set of security rules. Packets can for example be filtered by the port numbers, protocol, source and destination addresses."
          },
          {
            front: "<h4>What is a DMZ?</h4>",
            back:
              "A <i>Demilitarized Zone</i> is a logical or physical subnet that separates an inernal LAN from other networks to achieve an additional layer of security to the LAN. Services that communicate with the extranet are located in the DMZ while the internal LAN remains unreachable. Often two firewall are used (one destined only to the DMZ and an one for the internal LAN)."
          },
          {
            front: "What is a <b>PKI</b>?",
            back:
              "A Public Key Infrastructure consists of a certification authority (CA), a registration authority, a repository and an archive."
          },
          {
            front: "<h4>What is symmetric encryption?</h4>",
            back:
              "Symmetric encryption uses a single key to both encrypt and decrypt data. The station encrypting and the station decryption know both the secret key and which encryption algorithm was used to encrypt the message. Symmetric encryption provides confidentiality and authentication of the communication partner (if only the authorized principles have the key and nobody else)."
          },
          {
            front: "What is the advantage of symmetric encryption?",
            back:
              "Symmetric encryption is a lot faster (it requires a lot less process pwoer)."
          },
          {
            front: "What are the weaknesses of symmetric encryption?",
            back:
              "The distribution of the secure key is problematic and it has scalability problems as a lot of keys are required when the number of people grow. Furthermore, it provides only confidentiliaty."
          },
          {
            front: "What are the two main types of symmetric encryption?",
            back:
              "<ul><li><b>Stream ciphers</b> is a byte-per-byte process that combines one byte (or bit) of the plaintext with one byte of the key to create the ciphertext.</li><li><b>Block ciphers</b> applies a symmetric key to a block of bytes. As the block block is fixed in size, a padding is added if the length doesn't add up.</li></ul>"
          },
          {
            front: "What are the 6 aspects of security?",
            back:
              "Authentication, Confidentiality, Integrity, Non-Repudiation, Access Control, Availability"
          },
          {
            front: "What is understood by the process '<b>signing</b>'?",
            back:
              "Signing is used to verify the information source. A message is encoded with the private key and can be easily decrypted by anyone with the public key."
          },
          {
            front:
              "What are <b>trapdoor permutations</b> and how are they used in public key cryptography?",
            back:
              "A trapdoor function is easy to compute in one direction, but hard to find its inverse. In asymmetric encryption for example, we can give the the public key safely to the user as it will not help him to invert the function."
          },
          {
            front: "<h4>What is asymmetric encryption used for?</h4>",
            back: "Digital signatures and key exchange."
          },
          {
            front: "When is a cryptosystem <i>semantically secure?",
            back:
              "It is considered semantically secure when we can not retrieve any useful information from the ciphertext about the plaintext. This is normally the case if the encryption algorithm is non-deterministic or when we add a padding to it. A cryptosystem is considered <b>perfectly secret</b> when no information at all can be retrieved from the ciphertext about the plaintext."
          },
          {
            front: "What is a <b>certification authority</b> (CA)?",
            back:
              "A Certification authority issues digital certificates that are used to verify the ownership of a pulbic key. Both, the owner of the certificate and the other party have to trust the CA. Therefore a CA is also called a 'trusted third party'. A public key can be relied on if the certificate is signed by a trusted CA."
          },
          {
            front: "What is a <b>digital signature</b> used for?",
            back: "To prove the authenticity of an entity."
          },
          {
            front: "Why is a CA (certification authority) needed?",
            back:
              "With this trusted third party, the owners of the public key can verify that they can trust the public key that is used for encryption. With CAs the user can check the valifity of the provider."
          },
          {
            front:
              "How can asymmetry in public-key cryptosystems be achieved using factorization?",
            back:
              "The asymmetry is usually achieved by using <i>prime factorization</i> which takes a long time for large prime numbers. Only the person who knows the prime factors (the one with the private key) can decrypt the message. The parties with the public key would first have to factor the number which takes too long."
          },
          {
            front: "What is X.509?",
            back:
              "X.509 is a standard used by TLS, etc. for defining the format of public key certificates."
          },
          {
            front: "What is a <b>certificate chain</b>?",
            back:
              "Digital certificates are verified with a chain of trust. The user can verify the certificate issuer with the certificate hierarchy. The chain terminates with the root CA certificate that is signed by the CA itself. For increased security, intermeidate certificates are used. The CA signs the intermediate certificate with the private key."
          },
          {
            front: "What are examples of <b>social engineering</b> attacks?",
            back:
              "Social Engineering involes manipulation of people to get access to confidential information. For example Baiting (give them something they want - like a new movie), Phising (imitation of a trusted source)"
          },
          {
            front: "<h4>What is spear phishing?</h4>",
            back:
              "Spear phising is a type of phishing where a specific individual is targeted. This is done by gathering or/and using personal information of the target."
          },
          {
            front:
              "When does it make sense to encrypt a message with the private key?",
            back: "To prove <i>authenticity<i>."
          },
          {
            front: "What are the TLS (Transport Layer Security) protocols?",
            back:
              "<ol><li><b>Handshake protocol</b> and change cipher spec. protocol: to negotiate the ciphersuite and establish the keys.</li><li><b>Record protocol:</b> uses the keys from the handshake to provide confidentiality, integrity (using a MAC) and authenticity of application layer data. It provides end-to-end encryption using symmetric encryption. It does encrypt, calculate a checksum (MAC), compress and transmit the data to the peer. On the receiver side the inverse is done (decrypt, decompress, verify checksum, reassemble fragments and finally delivering the message to upper protocol layers.</li><li><b>Alert protocol:</b> used for warnings and errors.</li></ol>"
          },
          {
            front: "<h4>What is PGP (Pretty Good Privacy)?</h4>",
            back:
              "PGP is a security method to encrypt and sign messages often used for e-mail security. PGP uses asymmetric encryption for the exchange of keys and symmetric encryption for the actual message. PGP offers services for authentication and confidentiality."
          },
          {
            front: "In PGP, which steps are involved for the authentication?",
            back:
              "Authentication is done with digital signatures. <ol><li>The sender creates a message.</li><li>A hash code of the message is generated using SHA-1.</li><li>The sender crypts the hash code with his private key using RSA.</li><li>The encrypted hash code is prepended to the plaintext message.</li><li>The receiver decrypts the hash code using the sender's public key.</li><li>Finally, the receiver generates a new hash code of the message and compares it with the the decrypted hash code. The message is authentic if the two hash codes are the same.</li></ol>"
          },
          {
            front: "How does PGP achieve confidentiality?",
            back:
              "For the encryption of messages symmetric encryption with a session key that is bound to the message is used. <ol><li>The sender genrerates for every message a unique session key. Then, the message is encrypted using the session key.</li><li>The public key is used to encrypt the session key, which then is prepended to the message.</li><li>The receiver can then decrypt the session key using his private key (RSA).</li><li>The session key is then used to decrypt the original message.</li></ol>"
          },
          {
            front:
              "Can the service for confidentiality and authentication be used simultanously in PGP - if yes, how?",
            back:
              "Yes. <ol><li>A signature is generated with the private key and prepended to the message (message gets signed).</li><li>The plaintext and the signature is then ecrypted using the session key.</li><li>The session key is encrypted using RSA with the public key of the recipient.</li></ol>"
          },
          {
            front: "How does PGP achieve email compatbility?",
            back:
              "The bytes of the cipertext are encoded to ASCII with the Raidx-64 encoding."
          },
          {
            front: "<h4>What is RSA?</h4>",
            back:
              "RSA (<i>Rivest–Shamir–Adleman</i>) is a asymmetric cryptosystem used for data transmission. The asymmetry in RSA is achieved by using prime factorization."
          },
          {
            front: "What are the vulnerabilities of RSA and can they be fixed?",
            back:
              "<ul><li>RSA is a deterministic algorithm (without a random component). Therefore, the attacker can launch a plaintext attack in which he tries to guess the plaintext by encrypting it and then check if it is 'similar' to the ciphertext.</li><li>RSA is a homomorphic algorithm</li><li>Side channel attacks such as the timing attack which exploits timing variability of the implementation</li></ul> The first two problems can be fixed by introducing a randomized padding."
          },
          {
            front: "What is MD5?",
            back:
              "MD5 is a one-way hashing algorithm that is often used for verifying the integrity of a file."
          },
          {
            front: "What is a VPN?",
            back:
              "A <i>Virtual Private Network</i> interconnect networks or hosts in a secure way accross a public network. The most popular are TLS based VPNs and IPSec VPN."
          },
          {
            front: "What is confidentiality (network security)?",
            back:
              "Limted access to information. For example an encrypted message where only the authorized parties have the key needed for the decryption is confidential."
          },
          {
            front:
              "What role does <b>Zero Knowledge Proof</b> (ZKP) play in the authentication and which properties must be satisfied?",
            back:
              "In ZKP the goal is to proof that you have the valid credentials without having to give the verifier the credential details. A ZKP has the following three properties: <ul><li><b>Completeness:</b> If the statement of the prover is true then the verifier must be convinced that the prover is honest.</li><li><b>Soundness:</b> A honest verifier must only be convinced that a prover is honest when the statement is correct.</li><li><b>Zero-knowledge:</b> The verifier must never learn about the secret of the prover (only whether true or false).</li></ul>"
          },
          {
            front: "What is Advanced Encryption Standard?",
            back:
              "AES is a symmetric-key block cipher algorithm. AES replaces DES (Data Encryption Standard). Both are symmetric block ciphers. AES has a key-length of 128, 192, or 256 bits and is considered secure."
          },
          {
            front: "What is the <b>Extensible Authentication Protocol</b>?",
            back:
              "EAP offers several types of authentication. EAP-TLS for example is based on a Public Key Infrastructure (PKI)."
          },
          {
            front: "<h4>What is Wired Equivalent Privacy?</h4>",
            back:
              "WEP (Wired Equivalent Privacy) is a security protocol for wireless networks that aims to provide similar security as wired (Ethernet) networks have. With WEP data transmission is protected from the station up to the access point. WEP requires a pre-shared key (PSK)."
          },
          {
            front: "What is the main weakness of WEP?",
            back:
              "WEP is not safe against the <b>Known Plainttext Attack</b>. The keystream can be determined if the attacker knows plaintext for a given ciphertext."
          },
          {
            front: "What are the two authentication methods that WEP provides?",
            back:
              "<ul><li><b>Open System authentication:</b> a client is automatically authenticated regardless of whether he has the shared secret.</li><li><b>Shared Key authentication:</b> </li><li><b>Open System authentication:</b> the access point sends a clear text challenge that the client has to encrypt. The client is only authenticated if the clear text challenge is correctly encrypted.</li></ul>"
          },
          {
            front:
              "Why is WEP Shared authentication considered worse than Open authentication?",
            back:
              "In shared authentication mode, it is easy for the client to find the key as he can just analyze enough challenge frames to detect the WEP key."
          },
          {
            front: "<h4>What is WiFi Protected Access?</h4>",
            back:
              "The WPA security protocol was designed to replace WEP. It implements the TKIP (Temporal Key Integrity Protocol) and Message Integrity Check to verify messages and keys. For every packet the key changes. WPA also uses longer key and better encryption methods. WPA2 also uses a pre shared key, but uses AES to encrypt data."
          },
          {
            front:
              "What is the difference between <b>WPA2 Personal</b> and <b>WPA2 Enterprise</b>?",
            back:
              "Personal uses a shared key whereas enterprise uses unique credentials for every user. In enterprise mode, the AP verifies each login with a database using a RADIUS server.With RADIUS authentication with LDAP, Active Directory and certificates are possible."
          },
          {
            front: "How is WPA's MAC better than WEP's CRC?",
            back:
              "The MAC (Message Authentication Code) of WPA provides authenticity and data integrity."
          },
          {
            front: "What is Opportunistic Wireless Encryption?",
            back:
              "OWE is a new feature in WPA3 that offers encryption without authentication. This makes mainly sense for networks that don't use passwords such as guest hotspots."
          },
          {
            front: "Why is the primary security vulnerability of WPA2?",
            back: "The four-way handshake that is used to connect with a PSK."
          }
        ]
      }
    };
  }
};
</script>
